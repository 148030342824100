<template>
  <div class="itemArticle">
    <div class="itemArticle__content">
      <div class="itemArticle__container">
        <h1 class="itemArticle__title">{{itemData.name}}</h1>
        <div class="itemArticle__author">
          <div class="itemArticle__profilePicture" :style="{ backgroundImage: `url(${itemData.owner.image})` }"></div>
          <span>by {{ itemData.owner.name }}</span>
          <span
            v-if="itemData.created_at"
            style="padding-left: 5px;"
          >
            {{ formattedCreationDate }}
          </span>
        </div>
        <span class="productLikes">
          <span>
            <button
              @click="likeProduct()"
              class="transparentButton"
              :disabled="!$store.getters.userLoggedIn"
              :title="$store.getters.userLoggedIn ? 'Love it':'Please login to give it some love'"
            >
              <i
                class="fa-heart"
                :class="itemData.user_upvoted ? 'fas likeAnimation' : 'fal'"
              />
            </button>
            <div
              class="itemCard__likeCounter"
              style="margin-right: 10px"
              :class="{ 'itemCard__likeCounter--liked': itemData.user_upvoted }"
            >
              <p>{{ itemData.upvotes }}</p>
              <p>{{ itemData.upvotes }}</p>
            </div>
            <!-- <button
              disabled
              class="transparentButton"
              title="No comments yet">
              <i class ="fal fa-comment"/>
            </button>
            <div
              class="itemCard__likeCounter"
            >
              <p>0</p>
            </div> -->
          </span>
        </span>
        <div class="itemArticle__tags">
          <router-link
            class="itemArticle__tag"
            v-for="tag in itemData.tags"
            :to="getLinkPath('tag/' + encodeURIComponent(tag))"
            :key="tag">
            #{{tag}}
          </router-link>
        </div>
        <div class="itemArticle__text">
          <vue-markdown :key="itemData.id">{{itemData.description}}</vue-markdown>
        </div>
      </div>
      <div class="itemArticle__photo">
        <img :src="imgix(itemData.featured_picture, { width: 1200, height: 700 })"/>
      </div>
      <div class="itemArticle__container">
        <div v-if="itemData.ingredients && itemData.ingredients.length > 0" style="font-size: 14px">
          <span>Ingredients: </span>
          <span v-for="(ingredient, index) in itemData.ingredients" :key="index">
            {{ingredient.name}} ({{ingredient.quantity ? ingredient.quantity : null}} {{ingredient.unit ? ingredient.unit : null}}),
          </span>
        </div>
        <div v-if="itemData.servings">
          Servings: {{itemData.servings}}
        </div>
        <div v-if="itemData.preparation_time">
          Preparation time: {{itemData.preparation_time}} mins
        </div>
        <div v-if="itemData.difficulty_level">
          Difficulty level: {{ difficultyLevel }}
        </div>
        <div class="itemArticle__text">
          <vue-markdown :key="itemData.id">{{itemData.content}}</vue-markdown>
        </div>
      </div>
      <div v-if="itemData.pictures.length > 1">
        <div v-for="image in itemData.pictures" :key="image">
          <div v-if="image !== itemData.featured_picture" class="itemArticle__photoSecondary">
            <img :src="imgix(image, { width: 1200, height: 700 })" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Utils from '../utils.js'
import VueMarkdown from 'vue-markdown'
import imgix from '@/services/imgix'
import siteConstants from '@/const.js'
import httpClient from '@/services/httpClient'

export default {
  name: 'ItemArticle',
  created () {
    if (Utils.containsUppercase(this.slug)) {
      // contains upper case => replace to lowercase, everything else
      // should be updated/computed automatically
      // eslint-disable-next-line no-useless-escape
      const cleanSlug = Utils.cleanSlug(this.slug.toLowerCase())
      this.$router.replace(`/mag/${cleanSlug}`)
      return
    }
    this.getItemData(function (item) {
      if (item.item_type === 'recipe') {
        this.$router.replace(`/recipe/${this.$route.params.slug}`)
      }
      this.itemData = item
    }.bind(this))
  },
  components: {
    VueMarkdown
  },
  metaInfo () {
    return {
      title: this.itemData.name,
      link: [
        { rel: 'canonical', href: `${siteConstants.siteUrl}` + this.itemData.slug }
      ],
      meta: [
        { vmid: 'keywords', name: 'keywords', content: Object.values(this.itemData.tags).join`,` },
        { vmid: 'description', name: 'description', content: this.itemData.description },
        // OpenGraph data
        { property: 'og:title', content: this.itemData.name + ` - ${siteConstants.siteName}` },
        { property: 'og:site_name', content: `${siteConstants.siteName}` },
        // The list of types is available here: http://ogp.me/#types
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${siteConstants.siteUrl}/mag/` + this.itemData.slug },
        { property: 'og:image', content: this.itemData.featured_picture },
        // Often the same as your meta description, but not always.
        { property: 'og:description', content: this.itemData.description },

        // Twitter card
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.itemData.name + ` - ${siteConstants.siteName}` },
        { name: 'twitter:description', content: this.itemData.description },
        // Your twitter handle, if you have one.
        { name: 'twitter:image:src', content: this.itemData.featured_picture },

        // Google / Schema.org markup:
        { itemprop: 'name', content: this.itemData.name + ` - ${siteConstants.siteName}` },
        { itemprop: 'description', content: this.itemData.description },
        { itemprop: 'image', content: this.itemData.featured_picture }
      ]
    }
  },
  data: function () {
    return {
      siteConstants,
      title: '',
      itemData: {
        name: '',
        title: '',
        description: '',
        lang: '',
        tags: ['vegan', 'article'],
        featured_picture: '',
        pictures: [],
        owner: {
          image: ''
        },
        slug: '',
        visible: false,
        approved: false
      },
      imgix
    }
  },
  computed: {
    slug () {
      if (Utils.cleanSlug(this.$route.params.slug) !== this.$route.params.slug) {
        this.$router.replace(`/recipe/${Utils.cleanSlug(this.$route.params.slug.toLowerCase())}`)
      }
      return this.$route.params.slug
    },
    formattedCreationDate () {
      if (this.itemData.created_at) {
        const date = new Date(this.itemData.created_at)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`
      }

      return '01/01/1970'
    },
    difficultyLevel () {
      if (this.itemData.difficulty_level === 1) {
        return 'Easy'
      } else if (this.itemData.difficulty_level === 2) {
        return 'Medium'
      } else {
        return 'Hard'
      }
    }
  },
  methods: {
    async likeProduct () {
      if (this.itemData.user_upvoted) {
        try {
          this.itemData.user_upvoted = false
          this.itemData.upvotes--
          await httpClient.delete(`/item/${this.itemData.id}/upvote`)
        } catch {
          this.itemData.upvotes++
          this.itemData.user_upvoted = true
        }
      } else {
        try {
          this.itemData.user_upvoted = true
          this.itemData.upvotes++
          await httpClient.post(`/item/${this.itemData.id}/upvote`)
            .then(() => {
              this.$emit('openSnackbar', { message: '10 Points received', type: 'info' })
            })
        } catch {
          this.itemData.upvotes--
          this.itemData.user_upvoted = false
        }
      }
    },
    setTab (name) {
      this.activeTab = name
    },
    getItemData (callback) {
      axios.get(process.env.VUE_APP_API_URL + '/item/' + this.slug)
        .then(function (response) {
          callback(response.data)
        })
        .catch(error => {
          console.error(error)
          // this.$router.replace({ name: '404' })
          this.$store.commit('updateNotFound')
        })
    },
    cloneObject (obj) {
      var clone = {}
      for (var i in obj) {
        if (obj[i] != null && typeof (obj[i]) === 'object') {
          clone[i] = this.cloneObject(obj[i])
        } else {
          clone[i] = obj[i]
        }
      }
      return clone
    },
    getLinkPath (path) {
      return Utils.getLinkPath(path, this.$route)
    }
  }
}
</script>

<style scoped lang="stylus">
>>>img {
  max-width: 100%;
  width: auto;
  height: auto;
}
.itemArticle
  width 100%
  box-sizing border-box
  padding 30px 30px 35px 30px
  text-align left
  z-index 1
  .productLikes {
    margin-top auto
    // padding 30px 5px 10px 5px
    width 100%
    display flex
    align-items center
    justify-content space-between
    user-select none

    .itemCard {
      &__likeCounter {
        margin-left 4px
        display inline-block
        width auto
        height 21px
        overflow hidden
        p {
          font-size: 14px
          font-family 'Open Sans', Helvetica, Arial, sans-serif
          margin 0
          transform translateY(1px)
          transition 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
          padding 0
        }
        &--liked {
          p {
            transform translateY(-18px)
          }
        }
      }
      &__like {
        display flex
        align-items center
        button {
          display block
          cursor pointer
          width 70px
          height 70px
          background transparent
          outline none !important
          border 1px solid rgba(179,179,179,0.4)
          font-size 16px
          &[disabled] {
            cursor default
            color inherit
          }
          i {
            opacity .4
          }
        }
        &--liked {
          button {
            i {
              opacity 1
            }
          }
        }
      }
    }

    .transparentButton {
      border none
      cursor pointer
      background transparent
      padding 0
      font inherit

      &[disabled] {
        cursor default
        color #999
      }
    }

    span {
      margin-right: 9px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__content
    margin 20px auto 60px auto
    border-radius 5px
    background white
    max-width 800px
    padding 42px 0
    width 80%
  &__author
    display flex
    align-items center
    padding 8px 0 15px 0
    font-size 13px
  &__text
    font-size 18px
    line-height 31px
    color #000
  &__container
    padding 0 80px
    box-sizing border-box
    p
      font-family serif
      font-size 18px
      color #444
  &__title
    font-family 'Open Sans', Helvetica, Arial, sans-serif
    font-size 35px
    box-sizing border-box
    color #000
  &__author
    color #888
    font-size 12px
  &__shortDescription
    max-width 600px
  &__tags
    max-width 600px
  &__tag
    margin-right 8px
    display inline-block
    font-size 12px
    text-decoration none
    color #3B8EDE
  &__profilePicture
    display inline-block
    position relative
    width 30px
    height 30px
    margin-right 12px
    border-radius 60px
    background-color white
    background-repeat no-repeat
    background-size 100%
    background-position center
  &__photo
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    // min-height 526px
    // max-height 800px
    // position relative
    // width 100%
    margin 20px auto
    // overflow hidden
    img
      // position absolute
      // display block
      // width 100%
      // max-width 900px
      // margin 0 auto
      // left 0
      // top 50%
      // transform translateY(-50%)
  &__photoSecondary
    width 100%
    margin 20px auto
    img
      display block
      width 100%
      max-width 900px
      margin 0 auto
      left 0

@media screen and (max-width: 868px)
  .itemArticle
    padding 30px 5px
    &__photo
      height 400px
    &__content
      width 93%
    &__container
      padding 30px 20px

@media screen and (max-width: 400px)
  .itemArticle__photo
    height 260px
</style>
